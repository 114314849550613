<template>
    <div
        class="structure__block-wrap"
        :class="{ 'structure__block--root': data.isRoot }"
    >
        <div class="structure__block" :id="`block_${data.id}`">
            <div class="block__header">
                <span class="block__title" v-tooltip="{ content: data.name }">
                    <router-link
                        class="block__title"
                        tag="a"
                        :to="`/department/${data.id}`"
                        >{{ data.name }}</router-link
                    >
                </span>

                <div class="block__controls" v-if="current_user.is_admin">
                    <button
                        class="block__btn block__btn--edit"
                        type="button"
                        title="Добавить сотрудника"
                        aria-label="Добавить сотрудника"
                        @click="addUser"
                    >
                        <icon height="12" width="12" icon-color="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.88278 10.6977C7.34255 11.6641 8.32791 12.332 9.46929 12.332C11.0503 12.332 12.332 11.0503 12.332 9.46929C12.332 7.88824 11.0503 6.60654 9.46929 6.60654C8.76423 6.60654 8.11871 6.86142 7.61983 7.28407C7.43809 7.22617 7.22231 7.16161 7.08916 7.136C7.08091 7.11061 7.07267 7.0784 7.06539 7.04127C7.05482 6.98732 7.04746 6.93178 7.04265 6.88095C7.15984 6.7482 7.29988 6.56554 7.43943 6.3401C7.63542 6.02347 7.78491 5.68847 7.87017 5.33903C8.3911 5.05976 8.57368 4.08343 8.19491 3.55423L8.20717 3.43652C8.25371 2.9611 8.25085 2.54874 8.16107 2.12836C7.92388 1.01773 7.13079 0.332031 5.69582 0.332031C4.26086 0.332031 3.46777 1.01773 3.23058 2.12836C3.1408 2.54874 3.13794 2.9611 3.18448 3.43652L3.19674 3.55423C2.81793 4.08348 3.00058 5.05994 3.52163 5.33911C3.60691 5.68759 3.75631 6.02214 3.95215 6.33878C4.09178 6.56453 4.23188 6.74758 4.34903 6.88063C4.34422 6.93155 4.33686 6.98721 4.32626 7.04127C4.31901 7.07825 4.31081 7.11035 4.30259 7.13569C2.76692 7.42662 0.332031 8.96908 0.332031 10.3448C0.332031 10.5397 0.490048 10.6977 0.684972 10.6977H6.88278ZM9.71497 9.2236H10.4785C10.6142 9.2236 10.7242 9.33359 10.7242 9.46929C10.7242 9.60498 10.6142 9.71497 10.4785 9.71497H9.71497V10.4785C9.71497 10.6142 9.60496 10.7242 9.46929 10.7242C9.33362 10.7242 9.2236 10.6142 9.2236 10.4785V9.71497H8.46007C8.3244 9.71497 8.21438 9.60498 8.21438 9.46929C8.21438 9.33359 8.3244 9.2236 8.46007 9.2236H9.2236V8.46007C9.2236 8.32437 9.33362 8.21438 9.46929 8.21438C9.60496 8.21438 9.71497 8.32437 9.71497 8.46007V9.2236Z"/>
                        </icon>
                    </button>

                    <button
                        class="block__btn block__btn--edit"
                        type="button"
                        title="Редактировать отдел"
                        aria-label="Редактировать отдел"
                        @click="$emit('onEdit', data)"
                    >
                        <icon height="12" width="12" icon-color="currentColor">
                            <path
                                d="M0 9.49953V11.9999H2.50035L9.87802 4.62217L7.37767 2.12183L0 9.49953Z"
                            />
                            <path
                                d="M11.5985 1.7519L10.0416 0.195027C9.78154 -0.065009 9.35813 -0.065009 9.0981 0.195027L7.87793 1.4152L10.3783 3.91554L11.5984 2.69537C11.8585 2.43534 11.8585 2.01193 11.5985 1.7519Z"
                            />
                        </icon>
                    </button>

                    <button
                        class="block__btn block__btn--delete"
                        type="button"
                        title="Удалить отдел"
                        aria-label="Удалить отдел"
                        @click="$emit('onRemove', data)"
                    >
                        <icon height="16" width="16" icon-color="#989CAE">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.2908 1.60494H10.4329V0.52175C10.4329 0.233625 10.1993 0 9.91111 0H6.08946C5.80133 0 5.56771 0.233594 5.56771 0.52175V1.60494H2.70974C2.42161 1.60494 2.18799 1.83853 2.18799 2.12669V3.73159C2.18799 4.01972 2.42158 4.25334 2.70974 4.25334H13.2908C13.5789 4.25334 13.8125 4.01975 13.8125 3.73159V2.12669C13.8126 1.8385 13.579 1.60494 13.2908 1.60494ZM9.38933 1.60494H6.61121V1.04347H9.38933V1.60494Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M2.91211 5.29688L3.34473 15.5005C3.35655 15.7798 3.58645 16.0001 3.86598 16.0001H12.1337C12.4133 16.0001 12.6432 15.7798 12.655 15.5005L13.0876 5.29688H2.91211ZM6.42267 13.8784C6.42267 14.1665 6.18908 14.4001 5.90092 14.4001C5.6128 14.4001 5.37917 14.1665 5.37917 13.8784V7.41856C5.37917 7.13044 5.61277 6.89681 5.90092 6.89681C6.18905 6.89681 6.42267 7.13041 6.42267 7.41856V13.8784ZM8.52152 13.8784C8.52152 14.1665 8.28792 14.4001 7.99977 14.4001C7.71161 14.4001 7.47802 14.1665 7.47802 13.8784V7.41856C7.47802 7.13044 7.71161 6.89681 7.99977 6.89681C8.28792 6.89681 8.52152 7.13041 8.52152 7.41856V13.8784ZM10.6204 13.8784C10.6204 14.1665 10.3868 14.4001 10.0986 14.4001C9.81045 14.4001 9.57686 14.1665 9.57686 13.8784V7.41856C9.57686 7.13044 9.81045 6.89681 10.0986 6.89681C10.3868 6.89681 10.6204 7.13041 10.6204 7.41856V13.8784Z"
                                    fill="currentColor"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </icon>
                    </button>
                </div>
            </div>
            <div class="block__body">
                <div class="block__chiefs" v-if="data.chiefs.length">
                    <!--Слайдер Начальники-->
                    <swiper
                        class="block__slider block__slider--chiefs"
                        :options="swiperOption"
                        ref="chiefSwiper"
                    >
                        <swiper-slide
                            class="block__slide"
                            v-for="chief in data.chiefs"
                            :key="`chief_${chief.id}`"
                        >
                            <info-popover :data="chief">
                                <router-link
                                    :to="`/auth/profile/${chief.id}/`"
                                    class="slide__container"
                                >
                                    <img
                                        :src="
                                            chief.image_zoom_out ||
                                            defaultAvatar
                                        "
                                        class="slide__avatar"
                                        @error="errorAvatar"
                                    />
                                    <div class="slide__wrapper">
                                        <span class="slide__name">{{
                                            chief.short_name
                                        }}</span>
                                        <span
                                            class="slide__position"
                                            v-if="chief.positions"
                                            >{{ chief.positions.name }}</span
                                        >
                                    </div>
                                </router-link>
                            </info-popover>
                        </swiper-slide>
                        <div
                            class="swiper-button-next"
                            slot="button-next"
                        ></div>
                        <div
                            class="swiper-button-prev"
                            slot="button-prev"
                        ></div>
                    </swiper>
                </div>

                <div
                    v-if="staffList && staffList.length > 0"
                    class="block__staff"
                >
                    <span class="block__staff-title">
                        Сотрудники
                    </span>

                    <swiper
                        :options="staffSwiperOption"
                        ref="staffSwiper"
                        class="block__slider block__slider--staff"
                    >
                        <swiper-slide
                            v-for="staffItem in staffList"
                            :key="`staff_${staffItem.id}`"
                            class="block__slide"
                        >
                            <info-popover
                                class="slide__container"
                                :data="staffItem"
                            >
                                <router-link :to="`/auth/profile/${staffItem.id}/`">
                                    <img
                                        :src="
                                            staffItem.image_zoom_out ||
                                                defaultAvatar
                                        "
                                        class="slide__staff-avatar"
                                        alt=""
                                    />
                                </router-link>
                            </info-popover>
                        </swiper-slide>
                        <div class="swiper-button-next" slot="button-next" />
                        <div class="swiper-button-prev" slot="button-prev" />
                    </swiper>
                </div>
                <a
                    v-if="current_user.is_admin"
                    class="block__add-btn"
                    href="javascript:void(0)"
                    @click="onAdd(data, expandChildren)"
                >Добавить отдел</a>
            </div>
        </div>
        <a
            v-if="data.children_count > 0"
            class="block__collapse"
            href="javascript:void(0)"
            @click="expandChildren(data)"
        >
            <i
                class="block__collapse-icon"
                :class="{
                    'block__collapse-icon--expanded': data.children.length
                }"
            />
            {{ data.children_count }}
            {{ num2str(data.children_count, ['отдел', 'отдела', 'отделов']) }}
        </a>
    </div>
</template>

<script>
    import session from '@/api/session'
    import { mapState } from 'vuex'
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg'
    import Icon from '@/components/icon/Icon'

    export default {
        name: 'Item',
        components: {
            InfoPopover: () => import('./InfoPopover'),
            Icon
        },
        props: {
            data: {
                type: Object,
                required: false
            },
            loadData: {
                type: Function
            },
            showStructures: {
                type: Function
            },
            hideStructures: {
                type: Function
            },
            onAdd: {
                type: Function
            }
        },
        data() {
            return {
                swiperOption: {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                staffSwiperOption: {
                    slidesPerView: 'auto',
                    spaceBetween: 5,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                staffList: this.data.staff,
                defaultAvatar
            }
        },
        computed: {
            ...mapState('default_data', ['current_user']),
            chiefSwiper() {
                return this.$refs.chiefSwiper.swiper
            },
            staffSwiper() {
                return this.$refs.staffSwiper.swiper
            }
        },
        methods: {
            async expandChildren(data) {
                if (this.data.children.length === 0) {
                    const request = await session.get(
                        `/api/v1/structure/${data.id}/children/`
                    )
                    this.data.children.push(...request.data)

                    this.showStructures(data)
                } else {
                    this.hideStructures(data)
                }
            },

            /* Склонение числительных */
            num2str(n, text_forms) {
                n = Math.abs(n) % 100
                var n1 = n % 10

                if (n > 10 && n < 20) {
                    return text_forms[2]
                }

                if (n1 > 1 && n1 < 5) {
                    return text_forms[1]
                }

                if (n1 == 1) {
                    return text_forms[0]
                }

                return text_forms[2]
            },

            errorAvatar(event) {
                event.target.setAttribute('src', this.defaultAvatar);
            },

            addUser(data) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const AddUser = () => import(`../modals/AddUser`)
                    this.$modal.show(
                        AddUser,
                        {
                            struct_id: this.data.id,
                            struct_name: this.data.name,
                            valueUpdated: (user, status) => {
                                // add
                                if (status) {
                                    this.staffList.push(user);
                                // delete
                                } else {
                                    this.staffList = this.staffList.filter((variable) => variable.id !== user.id);
                                }
                            }
                        },
                        {
                            name: 'AddUser',
                            adaptive: true,
                            maxWidth: 768,
                            width: '100%',
                            height: 'auto'
                        }
                    )
                }
            },
        },
        mounted() {
            this.$emit('item-loaded')
        }
    }
</script>

<style lang="scss">
    @import '#sass/v-style';

    $bg-green: #b9d9b9;
    $yellow: #fbcf56;
    $gray: #dbdbdb;
    $icon-gray: #cccccc;
    $red: #e30000;
    $support-line: #e0e3f0;
    $dark-gray: #a4aebd;
    $dark-gray--hover: #909caf;

    .structure {
        &__block {
            display: flex;
            flex-direction: column;
            position: relative;
            top: 0;
            left: 0;
            max-width: 250px;
            max-height: 225px;
            height: 225px;
            width: 250px !important;
            background: #fff;
            border: 4px solid;
            border-color: $support-line;

            &-wrap {
                position: relative;
            }

            .slide {
                &__staff-avatar {
                    max-width: 35px;
                    max-height: 35px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .block {
                &__slider {
                    width: 100%;
                    .swiper-button-prev:after,
                    .swiper-button-next:after {
                        font-size: 0.875rem;
                        color: $dark-gray !important;
                    }
                    .swiper-button-next {
                        right: -10px !important;
                    }
                    .swiper-button-prev {
                        left: -10px !important;
                    }
                    &--staff {
                        width: 100%;
                        padding: 0 20px;
                        .block__slide {
                            width: auto !important;
                        }
                        .wrapper1 {
                            padding: 0 15px;
                        }
                        .swiper-button-prev,
                        .swiper-button-next {
                            background: #fff;
                        }
                    }
                    &--chiefs {
                        .block__slide {
                            width: 100% !important;
                        }
                        .slide {
                            &__container {
                                color: #000;
                                display: flex;
                                width: 100%;
                                padding: 0 15px;
                            }
                            &__wrapper {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                margin-left: 10px;
                            }
                            &__avatar {
                                height: 60px;
                                width: 60px;
                                min-width: 60px;
                                min-height: 60px;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                            &__name {
                                font-size: 14px;
                                text-align: left;
                                color: #000;
                                font-weight: 600;
                            }
                            &__position {
                                font-weight: 600;
                                font-size: 13px;
                                text-align: left;
                                color: $dark-gray;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }
                        }
                    }
                }
            }
        }
        .block {
            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid;
                border-color: $gray;
            }
            &__collapse {
                background-color: $dark-gray !important;
                //border: 1px solid #a7b7d8;
                border-bottom-left-radius: 32px;
                border-bottom-right-radius: 32px;
                width: 58%;
                display: flex;
                margin: 0 auto;
                justify-content: center;
                align-items: center;
                font-size: 0.875rem;
                color: #ffffff;
                position: relative;
                bottom: 1px;
                min-height: 28px;
                &:hover {
                    background-color: $dark-gray--hover !important;
                }
                &-icon {
                    margin-right: 5px;
                    transition: all 5s ease-in-out;
                    @include icon($angle-down-white, 15px);
                    &--expanded {
                        transform: rotate(180deg);
                    }
                }
            }
            &__title {
                color: #000;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
            }
            &__body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                height: 100%;
                padding: 0 5px;
                overflow: hidden;
            }
            &__chiefs {
                display: flex;
                margin-top: 10px;
            }
            &__staff {
                text-align: left;
                display: flex;
                flex-direction: column;
                margin: auto 0;
                &-title {
                    font-size: 0.875rem;
                    font-weight: 500;
                    color: #000;
                    padding: 0 15px;
                }
                &-empty {
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    color: $text-darkgray-3;
                    padding: 0 15px;
                }
            }
            &__controls {
                display: flex;
                align-items: center;
            }
            &__btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                width: 28px;
                height: 28px;
                border: 1px solid;
                border-color: $gray;
                border-radius: 50%;
                color: $icon-gray;
                background-color: transparent;
                cursor: pointer;
                transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
                &--edit {
                    margin-left: 2px;
                    &:hover {
                        border-color: $yellow;
                        color: $yellow;
                    }
                }
                &--delete {
                    margin-left: 2px;

                    &:hover {
                        border-color: $red;
                        color: $red;
                    }
                }
            }
            &__add-btn {
                margin: auto auto -1px;
                padding: 0 20px;
                font-size: 0.875rem;
                color: gray;
                border: 2px solid $gray;
                border-top-left-radius: 32px;
                border-top-right-radius: 32px;
                transition: 0.1s ease-in-out;
                min-height: 28px;
                &:hover {
                    background-color: $support-line;
                }
            }
        }
    }

    .structure__block--root {
        .structure__block {
            background: #fff;
            border: 4px solid $yellow;
        }
        .block {
            &__header {
                border-color: $yellow;
            }
            &__staff {
                display: none;
            }
            &__controls {
                display: none;
            }
            &__collapse {
                display: none;
            }
        }
    }
</style>
